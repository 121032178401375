@import "../Assets/Styles/style";

.search-bar {
  // width: 30px;
  height: 38px;
  padding: 0;
  margin: 0 15px 0 0;
  position: relative;
  /*overflow: hidden;*/
  // border-right: 0.063rem solid rgba(255, 255, 255, 0.15);
  transition: all 0.25s linear;

  input {
    width: 0;
    float: right;
    border: none;
    transition: all 0.25s linear;
    background: #fff;
    border-radius: 30px;
    height: 100%;
    font-weight: 600;
    padding: 0 3.1rem 0 1.5rem;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in;

    &.show-search-bar {
      width: 200px;
      visibility: visible;
      opacity: 1;

      + button {
        color: #000;
      }
    }

    &::placeholder {
      color: #444;
    }

    &:focus {
      box-shadow: 0 0 0;
    }
  }

  button {
    background: none;
    border: none;
    outline: none;
    color: #fff;
    transition: all 0.25s linear;
    position: absolute;
    right: 7px;
    top: 0px;
    padding: 7px 10px;

    i {
      font-size: 1.3rem;
    }

    &:hover {
      color: #ddd;
    }
  }
}

.search-bar.searchOpen {
  width: 250px;
}
.search-bar.searchOpen input {
  width: 190px;
}

.global-search-dropdown {
  position: absolute;
  height: 326px;
  background: #fff;
  width: 360px;
  top: 46px;
  right: 0;
  z-index: 10;
  box-shadow: 0 5px 7px 0 #00000021;
  border-radius: 4px;
  padding: 1rem;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in 0.3s;
  overflow: auto;
  overflow-x: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .global-search-dropdown-inner {
    .global-search-list {
      display: flex;
      padding: 10px 0;
      cursor: pointer;
      text-decoration: none;

      + .global-search-list {
        border-top: 1px solid $border-color;
      }

      &:hover {
        text-decoration: none;
      }

      .search-img {
        margin-right: 0.8rem;
        flex-shrink: 0;
      }

      .search-avtar {
        width: 30px;

        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 50%;
          border: 1px solid $border-color;
        }
      }

      h5 {
        font-size: $text-size-xs;
        font-weight: 600;
        margin-bottom: 0.2rem;
        color: $primary-color;
      }

      h6 {
        font-size: $text-size-xxs;
        font-weight: 600;
        margin-bottom: 0.2rem;
        color: #8087a2;
      }

      p {
        white-space: normal;
        font-size: $text-size-xs;
        margin-bottom: 0;
        color: #8087a2;
        i {
          vertical-align: middle;
          margin-right: 2px;
        }
      }

      .search-details {
        padding-right: 50px;
        position: relative;
        flex: 1 1 0;

        .search-type {
          position: absolute;
          right: 0;
          top: 4px;
          font-size: 1.4rem;
          color: $border-color;
        }
      }
    }
  }
}

@media (min-width: 520px) and (max-width: 1024px) {
  .global-search-dropdown {
    top: 56px;
    right: 30px;
  }
}

@media (max-width: 500px) {
  .global-search-dropdown {
    top: 56px;
    right: 15px;
    left: 15px;
    width: auto;
  }
}

/**/
.primary-navbar {
  margin-bottom: -1px;
  .nav-link {
    border-bottom: 3px solid transparent;
    &.active {
      border-bottom-color: $warning-color;
    }
  }
}

/**/

.navbar {
  padding: 0 1.5rem;
  .navbar-brand {
    img {
      max-height: 32px;
    }
  }
  .dropdown {
    .dropdown-menu {
      margin-top: 10px;
    }
  }
  .navbar-nav {
    .nav-link {
      padding: 0.85rem 0.7rem;
      color: #fff;
    }

    &.admin-action-navbar {
      .dropdown-toggle {
        padding: 0.68rem 1rem;
      }
    }
  }
}

.nav-link {
  &:focus,
  &:hover {
    outline: none;
  }
}

.navbar-nav {
  .dropdown-toggle {
    &::after {
      content: "";
      background-image: url(../Assets/Images/arrow-down-s-line.svg);
      background-repeat: no-repeat;
      background-size: 14px auto;
      height: 10px;
      width: 14px;
      border: none;
      position: relative;
      top: 2px;
    }
  }
}
.dropdown-menu {
  &.dropdown-menu-right {
    right: 0 !important;
    left: auto !important;
  }
}

/* ============ only desktop view ============ */
@media all and (min-width: 992px) {
  .navbar {
    .navbar-nav:not(.admin-action-navbar) {
      .nav-item {
        .dropdown-menu {
          display: block;
          opacity: 0;
          visibility: hidden;
          transition: 0.3s;
          margin-top: 0;
          &.fade-down {
            top: 80%;
            transform: rotateX(-75deg);
            transform-origin: 0% 0%;
          }

          &.fade-up {
            top: 180%;
          }
        }

        &:hover {
          .nav-link {
            color: #fff;
          }
        }

        &:hover {
          .dropdown-menu {
            transition: 0.3s;
            opacity: 1;
            visibility: visible;
            top: 100%;
            transform: rotateX(0deg);
          }
        }
      }
    }
  }
}
/* ============ desktop view .end// ============ */
/**/
.admin-action-navbar {
  display: flex;
  align-items: center;
  flex-direction: row;
  .admin-action-dropdown {
    .dropdown-menu {
      position: absolute;
    }
  }
}

.admin-action-dropdown a {
  display: flex;
  align-items: center;
}

@media (max-width: 1199px) {
  .navbar {
    .navbar-nav {
      .nav-link {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
      }
    }
  }
  .navbar {
    .admin-action-navbar {
      .nav-link {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
      }
      .dropdown-menu {
        position: absolute;
      }
    }
  }
}

@media (max-width: 991px) {
  .navbar {
    .navbar-brand {
      margin: 6px 0 6px 50px;
    }
    .navbar-toggler {
      position: absolute;
      left: 10px;
      top: 7px;

      &:focus {
        outline: 0;
      }
    }
    .navbar-collapse {
      position: fixed;
      left: -280px;
      top: 52px;
      bottom: 0;
      background-color: $bg-dark;
      z-index: 9999;
      width: 280px;
      height: 100% !important;
      transition: all 0.3s ease-out;
      .nav-link {
        padding-left: 1.75rem !important;
        border-bottom: 0.063rem solid rgba(255, 255, 255, 0.05);
      }
      .dropdown-menu {
        background-color: rgba(0, 0, 0, 0.15);
        padding: 0;
        a {
          color: #fff;
          padding: 0.75rem 2.5rem;
          border-bottom: 0.063rem solid rgba(255, 255, 255, 0.05);

          &.active,
          &:active {
            background-color: transparent;
            color: #fff;
          }
        }
      }
      &.show {
        left: 0;
      }
    }
  }
}
/* Notification Dropdown */
.navbar {
  .notification-dropdown {
    .dropdown-menu {
      width: 340px;
      padding: 0 !important;
      margin-top: 0.8rem;

      .notification-title {
        padding: 0.8rem 1rem;
        background-color: $primary-color;
        position: relative;
        display: flex;
        justify-content: space-between;

        &::before {
          content: '';
          position: absolute;
          top: -5px;
          right: 17px;
          width: 14px;
          height: 14px;
          background-color: $primary-color;
          transform: rotate(45deg);
        }

        h4 {
          margin-bottom: 0;
          color: #fff;
          font-size: 1rem;
        }

        span {
          display: inline-block;
          background: #fff;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          font-size: 0.75rem;
          font-weight: 700;
          color: $primary-color;
          line-height: 18px;
          text-align: center;
        }
      }

      .notification-list-outer {
        max-height: 400px;
        overflow-x: auto;
      }

      .dropdown-item {
        border-radius: 0;
        padding: 0.8rem 1rem;

        ~ .dropdown-item {
          border-top: 0.063rem solid #eaeaea;
        }
        &.view-all-notification {
          padding: 0;
          .btn, a {
            display: block;
            padding: 0.7rem;
            width: 100%;
            border-radius: 0;
            background-color: #f9f9f9;
            color: $primary-color;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
          }
        }

        .small-avtar {
          text-align: center;
          background-color: $primary-color;
          width: 34px;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.6rem;
          i {
            margin-right: 0;
            color: #fff;
          }
        }

        h6 {
          font-size: $text-size-xs;
          font-weight: 600;
          margin-bottom: 0.2rem;
        }

        .notification-title-with-time {
          display: flex;

          h6 {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            white-space: normal;
          }

          .notification-time {
            font-size: 0.7rem;
            font-weight: 600;
            color: #a4abc5;
            display: inline-block;
            padding-left: 10px;
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
          }
        }

        p {
          white-space: normal;
          font-size: $text-size-xxs;
          margin-bottom: 0;
          color: #8087a2;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        &:hover {
          background-color: #f9f9f9;
        }
      }
    }
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
    &.new-notify {
      .dropdown-toggle {
        &::before {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-image: none;
          background-color: $warning-color;
          position: absolute;
          right: 18px;
          top: 16px;
        }
      }
    }
  }
}

@media (min-width: 1300px) {
  .navbar {
    .navbar-nav {
      .nav-link {
        padding: 0.85rem 1rem;
      }
    }
  }
}

@media (max-width: 1440px) {
  .notification-dropdown {
    &.new-notify {
      .dropdown-toggle {
        &::after {
          right: 16px;
          top: 12px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .navbar {
    .notification-dropdown {
      .dropdown-menu {
        width: 270px!important;
        right: -50px!important;

        .notification-title {
          &::before {
            right: 50px;
          }
        }
        .dropdown-item {
          .media {
            flex-wrap: wrap;
            .media-body {
              padding-bottom: 1.3rem;
              position: relative;
            }
          }
          .small-avtar {
            flex-shrink: 0;
          }

          .notification-time {
            text-align: right;
            flex: 0 0 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            padding-left: 0!important;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .mobile-search-toggle {
    display: inline-block;

    .btn {
      color: #fff;

      i {
        font-size: 1.2rem;
      }
    }
  }

  .search-bar {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 52.36px;
    z-index: 12;
    background: #fbfbfb;
    box-shadow: 0 1px 10px #4391e621;
    padding: 0.5rem 2rem;
    height: auto;
    transform: translate(-50%, 0%);
    opacity: 0;
    visibility: hidden;
    transition: all linear 0.3s;

    input.show-search-bar {
      max-width: 400px;
      width: 100%;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #ddd;
      visibility: hidden;
    }

    button {
      top: 0.5rem;
      right: 2rem;
    }

    &.active {
      opacity: 1;
      visibility: visible;

      input.show-search-bar {
        visibility: visible;
      }
    }
  }
}
