.inline-block {
  display: inline-block;
}

.round-circle {
  border-radius: 50% !important;
}

.w-10 {
  width: 10%;
}

.w-18 {
  width: 18%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 20%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}

.v-top {
  vertical-align: top;
}
.v-middle {
  vertical-align: middle;
}

.flex-justify {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Card styles */
.card_main {
  background-color: transparent;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  /*margin: 12px;*/
  /*box-shadow: 9px 17px 45px -29px rgba(0, 0, 0, 0.44);*/
}

/* Card image loading */
.card__image {
  width: 100px;
  height: 100px;
}

.card__image.loading {
  width: 100px;
  height: 100px;
}

/* Card title */
.card__title {
  padding: 8px;
  font-size: 22px;
  font-weight: 700;
}

.card__title.loading {
  height: 1rem;
  width: 100%;
  margin-top: 1rem;
  border-radius: 3px;
}

/* Card description */
.card__description {
  padding: 8px;
  font-size: 16px;
}

.card__description.loading {
  height: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
}

/* The loading Class */
.loading {
  position: relative;
  background-color: #e2e2e2;
}

/* The moving element */
.loading::after {
  display: block;
  content: "";
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.2)),
    to(transparent)
  );

  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );

  /* Adding animation */
  animation: loading 0.8s infinite;
}

.lead-score-loader {
  padding: 1rem 2rem;
  div {
    height: 70px;
  }
}

.card__campaign.loading {
  width: 150px;
  width: 240px;
  height: 140px;
}

.campaign-builder-loader {
  .campaign-stage-box {
    background-color: #e3e3e3;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    min-width: 150px;
    max-width: 240px;
    margin: 0 auto;
    position: relative;
    min-height: 140px;
  }

  .h-jointer {
    border-bottom: 2px solid #e3e3e3;
    border-right: 2px solid #e3e3e3;
    border-radius: 0 0 0.35rem 0;
    width: 50%;
    display: inline-block;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 0.4rem 0 0.25rem 0;
    margin-right: -1px;

    span {
      visibility: hidden;
    }
  }

  .jointer {
    height: 44px;
    width: 2px;
    background-color: #e3e3e3;
    margin: 0 auto;
    display: block;
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 8px solid #e3e3e3;
      position: absolute;
      left: -4px;
      bottom: 0;
    }
  }

  .jointer-sec {
    .h-jointer.h-jointer-r {
      border-right: none;
      border-left: 2px solid #e3e3e3;
      margin-right: 0;
      margin-left: -1px;
      border-radius: 0 0 0 0.35rem;

      &::before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #e3e3e3;
        position: absolute;
        left: -4px;
        top: -5px;
      }
    }
  }
}

.funnel-loader-layers {
  display: table;
  width: 100%;

  ul {
    list-style: none;
    padding: 0;
    position: relative;
    max-width: 220px;
    float: left;

    li {
      border-top: 40px solid #d8d7d7;
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      height: 0;
      width: 220px;
      margin: auto;
      margin-bottom: 3px;

      &:nth-child(2) {
        width: 184px;
        border-top-color: #ddd;
      }
      &:nth-child(3) {
        width: 148px;
        border-top-color: #e8e8e8;
      }
      &:nth-child(4) {
        width: 112px;
        border-top-color: #e2e2e2;
      }
    }
  }

  .funnel-right-text {
    float: left;
    width: calc(100% - 220px);
    position: relative;
  }
}
.payment-gateway-loader {
  position: relative;

  .payment-logo {
    position: absolute;
    top: -35px;
    left: calc(50% - 50px);
  }
}
/* Loading Animation */
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

@media (max-width: 500px) {
  .cbl-outer:not(:first-child) {
    display: none;
  }

  .campaign-builder-loader {
    .d-flex > .card_main:not(:first-child) {
      display: none;
    }
  }
}

