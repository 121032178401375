$default-color: #212529; //#2a3950
$light-color: #7f8590;
$extra-light-color: #979ca4;
$primary-color: #4391e6;
$success-color: #8dc63f;
$danger-color: #f4584d;
$warning-color: #fda918;
$dark-blue-color: #2d629b;
$light-blue-color: #e3effb;
$light-green-color: #e8f4d9;
$light-brown-color: #b39059;
$orange-color: #f27428;
$purple-color: #a645d0;
$bg-dark: #1d2737;
$bg-medium: #fbfafa;
$bg-light: #fbfbfb;
$border-color: #e3e3e3;
$icon-color: #0874ea;

// Default colors
$color-orange: #fda918;
$color-green: #58c29a;
$color-blue: #007bff;
$color-red: #dc3545;
$color-dark-green: #17a2b8;
$color-light-red: #ff8f8f;
$active-blue: #4391e6;
$active-green: #9ac363;
$active-orange: #fb9b51;
$whatsapp-color: #058c7d;
$error-color : #ff2400;

// Font Size
$text-size-lg: 2rem;
$text-size-md: 1.875rem;
$text-size-sm: 1rem;
$text-size-xs: 0.875rem;
$text-size-xxs: 0.75rem;

// Social Media color

$facebook-color: #3a589e;
$linkedin-color: #0b75b3;
$google-color: #e1493b;
$twitter-color: #1cb7eb;

// Font Family
$font-montserrat: "Montserrat", sans-serif;

@mixin flex-center {
	display: flex;
	align-items: center;
}
@mixin form-control {
	font-family: "Open Sans", sans-serif;
	font-size: 0.875rem;
	height: calc(1.6em + 0.75rem + 2px);
	border-radius: 0.15rem;
	padding: 0.375rem 0.85rem;
	border: 0.063rem solid #e3e3e3;
	color: $default-color;
	width: 100%;
	/*-webkit-text-stroke: 0.1px;
    -webkit-text-stroke-color: $default-color;*/
	&:focus {
		box-shadow: 0 0 0 0.063rem $primary-color;
		border-color: $primary-color;
		outline: none;
	}
}
