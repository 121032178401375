@import "../../Assets/Styles/variables.scss";
.login-signup-form {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  font-family: $font-montserrat;
  position: relative;

  .or-divider {
    font-size: $text-size-xs;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;

    span {
      display: inline-block;
      padding: 0.3rem 1rem;
      position: relative;
      background-color: #fff;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $border-color;
    }
  }

  .btn {
    padding: 0.6rem 2rem;
  }

  .form-inner-main-bar {
    background-color: #fff;
    width: 100%;
    min-height: calc(100vh - 61px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  }

  .form-field-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 100%;
    max-width: 700px;
    padding: 2rem 2rem;
    box-shadow: 0 -3px 31px 0 #0000000d, 0 6px 20px 0 #00000005;

    .form-field-bar-inner {
      flex: 0 0 100%;
      max-width: 100%;

      .brand-logo-form {
        text-align: center;
        margin-bottom: 2rem;

        img {
          max-width: 200px;
        }
      }
      h3 {
        text-transform: uppercase;
        margin-bottom: 0.7rem;
        font-weight: 700;
      }
      h5 {
        font-weight: 600;
      }

      p {
        font-weight: 500;
      }

      .sign-in-with-social-media {
        display: flex;
        gap: 1rem;
        max-width: 500px;
        margin: 1rem auto 0;

        .btn {
          width: 100%;
          position: relative;
          height: 35px;

          &.facebook-btn {
            background-color: #3a589e;
            border-color: #3a589e;
          }
          &.google-btn {
            background-color: #e1493b;
            border-color: #e1493b;
          }
          &.linkedin-btn {
            background-color: #0b75b3;
            border-color: #0b75b3;
          }

          i {
            position: absolute;
            left: 1rem;
            font-size: 1.1rem;
          }
        }
      }
      form {
        margin-top: 2rem;
        .form-group {
          position: relative;

          .form-label {
            .show-tooltip {
              display: inline-block;
              color: $primary-color;
              margin-left: 10px;
              position: relative;
              font-size: 1.1rem;
              font-weight: normal;
              cursor: pointer;
              vertical-align: middle;
            }
          }

          .form-control {
            height: 40px;
            background-color: #f9f9f9;
          }

          .field-icon {
            position: absolute;
            right: 1rem;
            bottom: 6px;
            font-size: 1rem;
            color: $light-color;
          }

          .is-invalid ~ .field-icon {
            right: 2rem;
          }

          .input-group-prepend {
            .dropdown-toggle {
              padding: 0.6rem 1rem;
            }
          }
        }
      }

      .already-have-account {
        text-align: left;
        font-weight: 500;
        margin-top: 1rem;
        a {
          display: inline-block;
          padding: 0 0 0 0.5rem;
          font-weight: 600;
        }
      }

      .form-welcome-text {
        margin-bottom: 2rem;
        h2 {
          text-transform: uppercase;
        }

        p {
          font-size: $text-size-sm;
        }
      }
    }
  }

  .forgot-password {
    a {
      font-weight: 600;
      font-size: $text-size-xs;
    }
  }

  .choose-company-sign-in {
    .company-sign-in-list {
      display: flex;
      background-color: #fff;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      box-shadow: 0 1px 6px 0 #dae3ecba;
      position: relative;
      margin-top: 1rem;

      .company-logo {
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $border-color;
        background: $primary-color;
        color: #fff;
        font-weight: 600;
        font-size: 1.4rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .company-details {
        p {
          margin-bottom: 0;
          font-weight: 600;
          color: $light-color;
        }
      }

      .btn {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translate(0%, -50%);
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1rem;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.set-password-form {
  .choose-company-sign-in {
    max-width: 400px;
    margin-bottom: 1.5rem;
  }
}

.password-sent-form {
  .form-field-bar {
    box-shadow: 0 0 0;
    padding: 0;
    max-width: 600px;

    .form-field-bar-inner {
      h3 {
        margin-bottom: 1.5rem;
      }
    }

    .password-sent-inner {
      padding: 2rem;
      box-shadow: 0 -3px 31px 0 #0000000d, 0 6px 20px 0 #00000005;
      text-align: center;

      .email-icon {
        font-size: 4rem;
        color: $primary-color;
      }

      h4 {
        margin-bottom: 1.5rem;
      }
    }
  }
}

.account-setup-main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 1rem;

  .account-setup-inner {
    max-width: 900px;
    width: 100%;
    padding: 2rem;
    border: 1px solid $border-color;
     background-color: #fff;
    h2 {
      margin-bottom: 3rem;
      text-align: center;
      position: relative;
      font-weight: 700;

      &::before {
        content: "";
        position: absolute;
        bottom: -12px;
        left: calc(50% - 40px);
        width: 80px;
        height: 3px;
        background-color: $primary-color;
      }
    }

    .btn {
      padding: 0.6rem 2rem;
    }
  }
  .service-offer-type {
    .nav-pills {
      .nav-item {
        height: calc(100% - 3rem);
      }
      .nav-link {
        background-color: #fff;
        border: 2px solid #eee;
        box-shadow: 0 -3px 31px 0 #0000000d, 0 6px 20px 0 #00000005;
        color: $default-color;
        text-align: center;
        padding: 2rem 0.5rem 1.5rem;
        margin-bottom: 3rem;
        position: relative;
        height: 100%;

        img {
          max-width: 70px;
          filter: grayscale(100%);
          opacity: 0.7;
        }

        h5 {
          margin-top: 1rem;
          margin-bottom: 0;
        }

        .arrow-down {
          position: absolute;
          bottom: -50px;
          font-size: 2rem;
          color: $primary-color;
          left: 50%;
          transform: translate(-50%, 0);
          opacity: 0;
          transition: all ease-in 0.1s;
        }

        &.active {
          border-color: $primary-color;
          img {
            filter: grayscale(0%);
            opacity: 1;
          }

          .arrow-down {
            opacity: 1;
          }
        }
      }
    }

    .account-setup-tab-content {
      background-color: #f9f9f9;
      border: 1px solid #eee;
      padding: 1.5rem 2rem;

      h3 {
        margin-bottom: 1.4rem;
        position: relative;
        font-weight: 700;

        &::before {
          content: "";
          position: absolute;
          bottom: -8px;
          left: 0;
          width: 60px;
          height: 2px;
          background-color: $primary-color;
        }
      }

      ul {
        //display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        li {
          //flex: 0 0 48%;
          //max-width: 50%;
          list-style: none;
          padding: 0.5rem;
          font-weight: 500;
          font-family: $font-montserrat;
          margin: 10px 5px;
          // box-shadow: 1px 5px 5px 0px #ccc;
          border-bottom: 1px solid #ccc;
          .setup-text-with-icon {
            display: flex;
            i {
              vertical-align: middle;
              color: $primary-color;
              margin: 5px;
              font-size: 1.5rem;
            }
            div {
              flex: 0 1 80%;
            }
          }
        }
      }
    }
  }
}

.personal-details-form {


  .form-inner-main-bar {
    padding: 1px;
    justify-content: flex-end;
    background-image: url("../../Assets/Images/Final_dashboard.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .form-field-bar {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .form-sidebar {
    flex: 0 0 0px;
    max-width: 0px;
    display: flex;
    padding: 3rem 0;
  }

  .show-sidebar {
    .form-field-bar {
      flex: 0 0 64%;
      max-width: 64%;
    }

    .form-sidebar {
      flex: 0 0 36%;
      max-width: 36%;
      padding: 3rem 3rem;
    }
  }

  .form-field-bar {
    height: 100%;
    box-shadow: 0 0 0;
    transition: all 0.3s ease-in;

    .form-field-bar-inner {
      max-width: 700px;
    }
  }
  .form-sidebar {
    background-color: #f5f6fa;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    transition: all 0.3s ease-in;
    overflow: hidden;

    .form-sidebar-inner {
      height: 100%;
      .congratulation-text {
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
          text-transform: uppercase;
          font-weight: 700;
          position: relative;
          padding-bottom: 1rem;

          &::before {
            content: "";
            position: absolute;
            bottom: -0px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 60px;
            height: 3px;
            background-color: $primary-color;
          }
        }
        p {
          font-weight: 500;
          text-align: center;
        }
      }

      .setting-up-main {
        overflow: hidden;
        height: 175px;
        position: relative;

        .setting-up-inner {
          background-color: #fff;
          padding: 0 1rem;
          border-radius: 5px;
          display: flex;
          justify-content: left;
          align-items: center;
          font-size: $text-size-sm;
          font-weight: 600;
          overflow: hidden;
          opacity: 0.8;
          width: 100%;
          height: 80px;
          position: absolute;
          top: 175px;
          transition: all linear 0.3s;

          span {
            font-size: 1.5rem;
            width: 50px;
            height: 50px;
            display: inline-block;
            border-radius: 50%;
            background: #ddd;
            text-align: center;
            line-height: 50px;
            color: #fff;
            vertical-align: middle;
            margin-right: 10px;
            font-weight: normal;
            flex-shrink: 0;
          }

          &.active {
            opacity: 1;
            top: 0;
            &::after {
              content: "";
              width: 100px;
              height: 5px;
              background: $color-green;
              position: absolute;
              left: 0;
              bottom: 0;
              -webkit-animation: line_r_animation 3.3s infinite;
              animation: line_r_animation 3.3s infinite;
            }

            span {
              background: $primary-color;
            }
          }
        }
      }
      .setting-up-inner {
        &.remove {
          top: -80px;
          opacity: 0;
        }

        &.active {
          top: 0;
        }

        &.upcoming {
          // top: calc(80px + 15px);
          top: 80px;
          margin-top: 15px;
        }
      }
    }
  }
}

@keyframes line_r_animation {
  0% {
    width: 0px;
  }

  100% {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .login-signup-form {
    .form-inner-main-bar {
      align-items: flex-start;
      padding: 4rem 2rem;
      .form-field-bar {
        align-items: unset;
        max-width: 100%;
        box-shadow: 0 0 0 0;
      }
    }
  }
  .personal-details-form {
    .form-inner-main-bar {
      padding: 0;
      align-items: flex-start;

      &.show-sidebar {
        .form-field-bar {
          flex: 0 0 60%;
          max-width: 60%;
        }

        .form-sidebar {
          flex: 0 0 40%;
          max-width: 40%;
          padding: 2rem 1rem 1rem;
        }
      }
    }

    .form-field-bar {
      height: auto;
      box-shadow: 0 0 0;
    }

    .form-sidebar {
      height: 100%;
      align-items: flex-start;
      justify-content: center;

      .form-sidebar-inner {
        width: 100%;
        .congratulation-text {
          height: auto;
          margin-bottom: 1rem;

          h2 {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .account-setup-main {
    align-items: flex-start;
    padding: 3rem 1rem;

    .account-setup-inner {
      max-width: 100%;
      border: 0;
    }
  }
}

.not-sure-box {
  h4 {
    margin-bottom: 0;
  }
  .question-answer-outer {
    padding-top: 30px;
    display: flex;
    gap: 1.5rem;
  }

  .question-answer-list {
    flex: 1 1 0;
    max-width: 33.33%;
    background-color: #fff;
    border: 2px solid #eee;
    box-shadow: 0 -3px 31px 0 #0000000d, 0 6px 20px 0 #00000005;
    color: #212529;
    text-align: center;
    padding: 2rem;
    margin-bottom: 3rem;
    position: relative;
    cursor: pointer;

    img {
      max-width: 60px;
    }

    .selected-ans {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
      font-size: $text-size-lg;
      color: $primary-color;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s linear;
    }

    &.active {
      .selected-ans {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .question-pagination {
    text-align: center;
    font-weight: 600;
    span {
      color: $primary-color;
    }
  }
}

@media (max-width: 767px) {
  .login-signup-form {
    .form-inner-main-bar {
      padding: 1.5rem 1rem;
      align-items: flex-start;
      .form-field-bar {
        order: 2;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 1rem;
        box-shadow: 0 0 0;
        border: 1px solid #eee;

        .form-field-bar-inner {
          .brand-logo-form {
            margin-bottom: 1.5rem;

            img {
              max-width: 100px;
            }
          }
          h3 {
            font-size: $text-size-sm;
          }
          .sign-in-with-social-media {
            .btn {
              i {
                position: relative;
                left: 0;
              }
            }
          }
        }
      }
    }
  }

  .password-sent-form {
    .form-inner-main-bar {
      padding: 0;

      .form-field-bar {
        border: 0;
      }
    }
  }

  .account-setup-main {
    min-height: auto;
    display: flex;
    align-items: flex-start;
    padding: 0;

    .account-setup-inner {
      border: 0;
      padding: 2rem 1rem;
    }

    .service-offer-type {
      .row {
        .col:nth-child(2) {
          padding: 0;
        }
      }

      .nav-pills {
        .nav-link {
          padding: 1rem 0.5rem;
          img {
            max-width: 50px;
          }
          h5 {
            font-size: $text-size-xxs;
          }
        }
      }

      .account-setup-tab-content {
        padding: 1rem;

        ul {
          li {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
  .personal-details-form {
    .form-inner-main-bar {
      padding: 0;
      display: flex;

      .form-field-bar {
        flex: 0 0 100%;
        max-width: 100%;
        height: auto;
        box-shadow: 0 0 0;
        border: 0;
      }
      .form-sidebar {
        flex: 0 0 100%;
        max-width: 100%;
        height: 0;
        padding: 0;
        display: none;
      }

      &.show-sidebar {
        .form-field-bar {
          flex: 0 0 100%;
          max-width: 100%;
        }

        .form-sidebar {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 2rem 1rem 1rem;
          height: auto;
          display: block;
        }
      }
    }
  }

  .modal {
    .choose-company-form {
      .form-inner-main-bar {
        padding: 0;

        .form-field-bar {
          padding: 0;
          border: 0;
        }
      }

      .choose-company-sign-in {
        .company-sign-in-list {
          padding: 0.5rem;

          .company-logo {
            width: 40px;
            height: 40px;
          }

          h4 {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 280px) {
  .login-signup-form {
    .form-inner-main-bar {
      padding: 0;

      .form-field-bar {
        border: 0;
        box-shadow: 0 0 0;
      }
    }

    .btn {
      padding: 0.6rem 1rem;
    }

    .forgot-password {
      a {
        font-size: $text-size-xxs;
      }
    }
  }

  .account-setup-main {
    .service-offer-type {
      .nav {
        > .row {
          .col {
            flex: 0 0 100%;

            &:nth-child(2) {
              padding: 0 15px;
            }
          }
        }

        .nav-item {
          height: calc(100% - 1rem);
        }

        .nav-link {
          margin-bottom: 1rem;
        }
        .arrow-down {
          display: none;
        }
      }
    }
  }
}
